<template>
  <div>
    <!-- 头部区域 -->
    <div style="height: 60px; line-height: 60px; background-color: white; margin-bottom: 2px; display: flex">
      <div style="width: 300px">
        <img src="@/assets/logo.png" alt="" style="width: 40px; position: relative; top: 10px; left: 20px">
        <span style="margin-left: 25px;font-size: 24px">记账管理系统</span>
      </div>
      <div style="flex: 1; text-align: right; padding-right: 20px">
        <el-dropdown size="medium">
          <span class="el-dropdown-link" style="cursor: pointer">
            {{ admin.username }}<i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown" style="margin-top: -5px">
            <el-dropdown-item><div style="width: 50px; text-align: center" @click="logout">退出</div> </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>

    <!-- 侧边栏和主体 -->
    <div style="display: flex">
      <!-- 侧边栏导航 -->
      <div style="width: 200px; min-height: calc(100vh - 62px); overflow: hidden; margin-right: 2px; background-color: white">
        <el-menu :default-active="$route.path" router class="el-menu-demo">
          <el-menu-item index="home">
            <i class="el-icon-eleme"></i>
            <span>首页</span>
          </el-menu-item>
          <el-submenu index="/0">
            <template slot="title">
              <i class="el-icon-user"></i>
              <span>用户管理</span>
            </template>
            <el-menu-item index="user">用户列表</el-menu-item>
            <el-menu-item index="userOperater">用户操作</el-menu-item>
          </el-submenu>
          <el-submenu index="/1">
            <template slot="title">
              <i class="el-icon-user"></i>
              <span>管理员管理</span>
            </template>
            <el-menu-item index="addAdmin">添加管理员</el-menu-item>
            <el-menu-item index="admin">管理员列表</el-menu-item>
          </el-submenu>
          <el-submenu index="/2">
            <template slot="title">
              <i class="el-icon-picture-outline"></i>
              <span>数据统计</span>
            </template>
            <el-menu-item index="operateShow">记账方式</el-menu-item>
            <el-menu-item index="clearingTypeShow">记账类型</el-menu-item>
            <el-menu-item index="userShow">用户图表</el-menu-item>
          </el-submenu>
        </el-menu>
      </div>

      <!-- 主体数据 -->
      <div style="flex: 1; background-color: white;padding: 10px">
        <router-view/>
      </div>
    </div>
  </div>
</template>

<script>
import Cookies from "js-cookie";
export default {
  name: 'Layout.vue',
  data() {
    return {
      admin: Cookies.get('admin') ? JSON.parse(Cookies.get('admin')) : {}
    }
  },
  methods:{
    logout() {
      //清除浏览器用户数据
      Cookies.remove('admin')
      this.$router.push('/login')
    }
  }

}
</script>
import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '../views/Layout.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Login',
    component: () => import('@/views/login/Login.vue')
  },
  {
    path: '/',
    name: 'Layout',
    component: Layout,
    // redirect:'/login',
    children:[
      {
        path: 'user',
        name: 'User',
        component: () => import('@/views/user/User.vue')
      },
      {
        path: 'userOperater',
        name: 'UserOperater',
        component: () => import('@/views/user/UserOperate')
      },
      {
        path: 'home',
        name: 'Home',
        component: () => import('@/views/home/HomeView.vue')
      },
      {
        path: 'addAdmin',
        name: 'AddAdmin',
        component: () => import('@/views/Admin/AddAdmin.vue')
      },
      {
        path: 'admin',
        name: 'Admin',
        component: () => import('@/views/Admin/Admin.vue')
      },
      {
        path: 'operateShow',
        name: 'OperateShow',
        component: () => import('@/views/Image/OperateShow.vue')
      },
      {
        path: 'clearingTypeShow',
        name: 'ClearingTypeShow',
        component: () => import('@/views/Image/ClearingTypeShow')
      },
      {
        path: 'userShow',
        name: 'UserShow',
        component: () => import('@/views/Image/UserShow.vue')
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
